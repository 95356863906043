import React from "react";

function SharedLink({ url }) {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: url
        });
        // console.log('Link shared successfully!');
      } catch (error) {
        console.error("Error sharing link:", error);
      }
    } else {
      // console.log('Share API not supported');
    }
  };

  return (
    <div className="space-y-1 items-center text-center">
      <p className="text-black font-medium text-base">INVITATION LINK</p>
      <p
        className="text-base
                    text-center font-medium text-gray-700"
      >
        Share the link and Get 200 Bonus Coins Instantly
      </p>
      <p>{url}</p>
      <button
        className="w-20 text-center py-1 rounded-md
                  active:bg-blue-700 bg-blue-500 font-bold
                  text-white hover:bg-blue-700
                  focus:outline-none my-2"
        id="open-btn"
        onClick={() => handleShare()}
      >
        SHARE
      </button>
    </div>
  );
}

export default SharedLink;
